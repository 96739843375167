<template>
  <div class="skills">
    <div class="dashboard-summary">
      <div class="skill-card d-flex flex-wrap">
        <SkillCard
          v-for="(skill, i) in skillsGained"
          :key="i"
          :skill="skill"
          class="mb-2 mr-2"
        />
      </div>
    </div>

    <div class="dashboard-content py-4">
      <b-card class="add-skill">
        <div>
          <div
            class="add-skill__header p-1 mb-4 rounded d-flex justify-content-between align-items-center"
          >
            <div class="">
              <h2 class="font-weight-bold">Add new skill</h2>
            </div>

            <div class="position-relative">
              <SearchIcon class="position-absolute mt-3 ml-3" />
              <b-form-input
                v-model="q"
                type="text"
                size="lg"
                class="px-5 rounded w-100"
                placeholder="Search for skills"
              >
              </b-form-input>
            </div>
          </div>

          <div class="border rounded skill-tabs">
            <SkillTab
              :search-term="q"
              :tabs="tags"
              :skills="formattedSkills"
              @selected="selected"
            />
          </div>
        </div>
      </b-card>
      <SkillModal
        :skill="skill"
        :is-busy="mcqProcessing"
        @started="initialize"
        @hidden="unsetActive"
      />

      <SkillScheduleModal v-if="skill.name" :skill="skill" @scheduled="handleSchedule" />
    </div>
  </div>
</template>

<script>
import SearchIcon from "../../components/svg/SearchIcon.vue";
import SkillCard from "../../components/skills/SkillCard.vue";
import SkillTab from "../../components/skills/SkillTab.vue";
import SkillModal from "../../components/skills/SkillModal.vue";

import SkillScheduleModal from "@/components/skills/SkillScheduleModal.vue";

import { mapActions, mapState, mapGetters } from "vuex";

import routeQueryMixin from "@/mixins/routeQueryMixin";

export default {
  title: "Menaget - Manage your Skills",
  components: {
    SearchIcon,
    SkillCard,
    SkillTab,
    SkillModal,
    SkillScheduleModal,
  },
  mixins: [routeQueryMixin],
  data() {
    return {
      q: "",
      selectedSkill: {
        category: "",
        name: "",
      },
      mcqProcessing: false,
      selectedLevel: null
    };
  },
  computed: {
    ...mapState({
      tags: (state) => state.skill.tags,
      skill: (state) => state.skill.skill,
    }),
    ...mapGetters({
      formattedSkills: "skill/formattedSkills",
      skillsGained: "skillsGained",
    }),
  },
  created() {
    this.getTags();
    this.fetchSkills();
  },
  methods: {
    ...mapActions({
      fetchSkills: "skill/fetchSkills",
      fetchSkill: "skill/fetchSkill",
      fetchTags: "skill/fetchTags",
      initializeAssessment: "skill/initializeAssessment",
    }),
    getTags() {
      let loader = this.$loading.show();
      this.fetchTags()
        .then(() => {})
        .finally(() => {
          loader.hide();
        });
    },
    selected(skill) {
      this.selectedSkill = skill;
      this.getSkill(skill.id);
    },
    initialize(level) {
      if (this.skill.assessments[0] === "mcq") {
        this.initializeMcqAssessment(level);
      }
      if (this.skill.assessments[0] === 'interview') {
        this.initializeInterviewAssessment(level)
      }
    },
    getSkill(id) {
      let loader = this.$loading.show();
      this.fetchSkill(id)
        .then(() => {
          if (!this.skill.has_skill) {
            this.$bvModal.show("skill_modal");
            this.setQueryParam({ active_id: id });
          }

          if ((this.skill.has_skill || this.skill.ongoing) && !this.skill.mcq_ongoing) {
            this.$router.push(`skills/${id}/assessment-details`);
          }

          if (this.skill.mcq_ongoing) {
            this.$router.push(`skills/${id}/mcq`);
          }
        })
        .catch(() => {})
        .finally(() => {
          loader.hide();
        });
    },
    triggerActive() {
      this.getSkill(this.$route.query.active_id);
    },
    handleSchedule(form) {
      console.log(form)
      console.log(form.interviewDate.toLocaleDateString("fr-CA"))
      console.log(form.interviewTime.getHours())
      this.$toast.success("Interview scheduled succesfully!");
    },
    initializeMcqAssessment(level) {
      this.mcqProcessing = true;
      this.initializeAssessment({ id: this.skill.id, level: level })
        .then(() => {
          this.$router.push(`skills/${this.skill.id}/mcq`);
        })
        .catch(() => {
          // do some things here
        })
        .finally(() => {
          this.mcqProcessing = false;
        });
    },
    initializeInterviewAssessment(level) {
      this.selectedLevel = level
      this.$bvModal.show('skill_schedule_modal')
    }
  },
};
</script>

<style lang="scss" scoped>
.add-skill {
  border-radius: $size-10;

  &__header {
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }
}
</style>
